export enum UserStatus {
  AVAILABLE = 'AVAILABLE',
  DISCONNECTED = 'DISCONNECTED',
  BATHROOM = 'BATHROOM',
  LUNCH = 'LUNCH',
  CALL = 'CALL',
  ALL = 'ALL',
}

export enum StatusAgent {
  AVAILABLE = 'AVAILABLE',
  BATHROOM = 'BATHROOM',
  LUNCH = 'LUNCH',
  CALL = 'CALL',
  BREAK = 'BREAK',
  PREBREAK = 'PREBREAK',
  PAUSE = 'PAUSE',
  ADMINISTRATIVE = 'ADMINISTRATIVE',
  END = 'END',
}
